// main JS
var FE = {
    location: window.location,

    // Alphabetic Sitemap (A-Z)
    alphaSitemap: {
        alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'N', 'M', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
        init: function () {
            $('.frame-type-menu_subpages ul').addClass('csc-menu');
            $('ul.csc-menu').before('<ul class="alpha-sitemap-nav"></ul>');
            $(FE.alphaSitemap.alphabet).each(function( index, value ) {
                //console.log(value);
                if ( FE.alphaSitemap.getFirstLetterAppearance( value ) ) {
                    $('.alpha-sitemap-nav').append('<li class="linked"><a href="#' + value + '">' + value + '</a></li>');
                } else {
                    $('.alpha-sitemap-nav').append('<li>' + value + '</li>');
                }
            });
            FE.alphaSitemap.showRelevantEntriesOnly();
        },
        getFirstLetterAppearance: function( letter ) {
            var inUse = false;
            $('ul.csc-menu li').each(function() {
                var itemLetter = $(this).find('a').text().trim().charAt(0);
                if ( itemLetter === letter ) {
                    $(this).prepend('<h4 id="' + letter + '">' + letter + '</h4>');
                    $(this).addClass('section-start');
                    inUse = true;
                    return false;
                }
            });
            return inUse;
        },
        showRelevantEntriesOnly: function(e) {
            $('.alpha-sitemap-nav li a').on('click', function(e) {
                e.preventDefault();
                var curLetter = $(this).attr('href');
                var startElem = $('ul.csc-menu li').find('h4' + curLetter).parent();
                $('ul.csc-menu li').hide();
                $(startElem).show();
                $(startElem).nextUntil('.section-start').show();
            });
        }
    },
    contentSlider: {
        init: function() {
            var sliderSettings = {
                elem: '.frame-default',
                fadeSpeed: 1000,
                rotationSpeed: 5000,
                statusBar: false,
                controls: false
            };
            $('.content-welcome').objectSlider(sliderSettings);
        }
    },
    mobileNav: {
        init: function() {
            FE.mobileNav.indicateSubMenu();
            $('.burger-icon').on('click', function() {
                $(this).toggleClass('active');
                $('.nav-area').slideToggle();
                $('.nav-main li.open ul').hide();
                $('.nav-main li').removeClass('open');
                this.setAttribute('aria-expanded', 'true');
                if ( this.classList.contains('active') ) {
                    this.setAttribute('aria-expanded', 'true');
                } else {
                    this.setAttribute('aria-expanded', 'false');
                }
            });
        },
        indicateSubMenu: function() {
            $('.nav-main > ul > li').each(function() {
                if ( $(this).find('ul').length ) {
                    $(this).prepend('<span>&gt;</span>');
                }
                $(this).find('span').on('click', function() {
                    $(this).siblings('ul').slideToggle();
                    $(this).parents('li').toggleClass('open');
                });
            });
        }
    },
    iOsIframeFix: {
        init: function() {
            $('iframe').each(function() {
                var frameHeight = $(this).attr('height');
                $(this).wrap('<div class="iOs-iFrame-fix" style="height:' + frameHeight + 'px"></div>');
            });
        },
        isNeeded: function() {
            return /(iPad|iPhone|iPod)/g.test( navigator.userAgent );
        }
    },

    Video: {
        userConsentAvailable: false,
        init: function () {
            document.querySelectorAll('.video-keep-data-privacy[data-video-type="youtube"]').forEach(function (videoItem) {
                //console.log(videoItem);
                //videoItem.addEventListener('click', FE.Video.checkVideoLoading);
                videoItem.addEventListener('click', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    FE.Video.loadVideo(e.currentTarget);
                });
            });
            if (sessionStorage.getItem('youTubeConsent') === '1') {
                this.userConsentAvailable = true;
            }
        },
        loadVideo: function (videoItem) {
            const videoId = videoItem.dataset.videoId;
            const videoArea = videoItem.parentElement;

            //console.log(videoArea);

            // check potential permission via consent manager or any other persistent data such as session storage or cookie
            if ( FE.Video.userConsentAvailable) {
                videoArea.innerHTML = FE.Video.getYouTubeEmbedCode(videoId);
            } else {
                FE.Video.addUserConsent(videoArea, videoId);
                videoItem.nextElementSibling.classList.add('show');
            }

        },
        embedVideo: function (videoArea, videoId) {
            videoArea.innerHTML = FE.Video.getYouTubeEmbedCode(videoId);
        },
        getVimeoEmbedCode: function (videoId) {
            return '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/' + videoId + '?h=96a48e54c0&byline=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>';
        },
        getYouTubeEmbedCode: function (videoId) {
            return '<iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/' + videoId + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        },
        addUserConsent: function (videoArea, videoId) {
            const videoConsentHtml = `An dieser Stelle laden wir ein externes Video von YouTube.<br>
                                        Mir ist bewusst, dass dabei Daten mit den YouTube Servern ausgetauscht werden.<br>
                                        Weitere Informationen zu den <a href="https://policies.google.com/technologies/cookies?hl=de" target="_blank" rel="noopener">YouTube Datenschutzbedingungen</a>.
                                        <div class="btn-group"><button class="btn-video-confirm">ist für mich OK</button><button class="btn-video-deny">nein, möchte ich nicht</button>`;

            const videoConsent = document.createElement('div');
            videoConsent.classList.add('external-video-consent');
            videoConsent.innerHTML = `<div class="external-video-consent-inner">${videoConsentHtml}</div>`;
            videoArea.append(videoConsent);
            videoArea.querySelector('.btn-video-confirm').addEventListener('click', function (e) {
                FE.Video.embedVideo(videoArea, videoId);
                sessionStorage.setItem('youTubeConsent', '1');
                FE.Video.userConsentAvailable = true;
            });
            videoArea.querySelector('.btn-video-deny').addEventListener('click', function (e) {
                e.target.closest('.external-video-consent').classList.remove('show');
            });
        }
    }

};

// init custom functions
(function ($, window, document, undefined) {

    $(function () {
        FE.alphaSitemap.init();

        if ( $('.content-welcome').length ) {
            FE.contentSlider.init();
        }

        FE.mobileNav.init();

        if ( $('iframe').length && FE.iOsIframeFix.isNeeded() ) {
            FE.iOsIframeFix.init();
        }

        if ( document.querySelector('.video-keep-data-privacy[data-video-type="youtube"]') ) {
            FE.Video.init();
        }

    });

}(jQuery, window, document));
